import './App.css';

function App() {
  return (
    <div className="flex w-full h-dvh items-center relative">
      <video 
        autoPlay 
        muted 
        loop 
        playsInline
        className="absolute top-0 left-0 w-full h-full object-cover -z-10"
        src="/assets/images/jelly_vid_1.mp4"
      />
      <div className="flex items-center gap-4">
        <svg className="animate-slideIn" 
             style={{ animation: "slideIn 0.5s ease-out 1.3s forwards",
                     opacity: 0 }}
             xmlns="http://www.w3.org/2000/svg" width="27" height="8" viewBox="0 0 27 8" fill="none">
          <path d="M0 3.2H26.6667V4.8H0V3.2Z" fill="white"/>
          <path d="M21.8672 8V0H23.4672V8H21.8672Z" fill="white"/>
        </svg>
        <div className="header-3"
             style={{ animation: "fadeIn 0.5s ease-out 1.8s forwards",
                     opacity: 0 }}>
          OLDHOLY
        </div>
      </div>
    </div>
  );
}

export default App;


// import './App.css';

// function App() {
//   return (
//     <div className="flex w-full h-dvh items-center bg-cover bg-center bg-no-repeat animate-flicker" 
//          style={{ backgroundImage: "url('/assets/images/jelly_2.png')",
//                  animation: "flicker 1s ease-in-out 1s" }}>
//       <div className="flex items-center gap-4">
//         <svg className="animate-slideIn" 
//              style={{ animation: "slideIn 0.5s ease-out 1.3s forwards",
//                      opacity: 0 }}
//              xmlns="http://www.w3.org/2000/svg" width="27" height="8" viewBox="0 0 27 8" fill="none">
//           <path d="M0 3.2H26.6667V4.8H0V3.2Z" fill="white"/>
//           <path d="M21.8672 8V0H23.4672V8H21.8672Z" fill="white"/>
//         </svg>
//         <div className="header-3"
//              style={{ animation: "fadeIn 0.5s ease-out 1.8s forwards",
//                      opacity: 0 }}>
//           OLDHOLY
//         </div>
//       </div>
//     </div>
//   );
// }

// export default App;
